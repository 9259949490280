import styled from '@emotion/styled';
import { BoxProps } from 'packages/formidable/components/props';
import { createStyles } from 'packages/formidable/core/functions';
import React, { FC, PropsWithChildren } from 'react';

const BoxSC = styled.div<any>`
  position: relative;
  display: flex;
  align-self: ${props => !props.self && !props.alignSelf && 'auto'};
  flex-direction: ${props =>
    !props.direction && !props.flexDirection && 'column'};
  width: 100%;

  > *:last-child {
    margin-bottom: 0;
  }

  div:not(.grid, .has-attributes) > & {
    margin-bottom: ${(props: any) =>
      props && props.theme && props.theme.spacing && props.theme.defaultSpace
        ? props.theme.spacing[props.theme.defaultSpace]
        : '0'};

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .item-list div {
    margin-bottom: 0 !important;
  }

  div.grid + &,
  div.flex + & {
    margin-top: ${(props: any) =>
      props && props.theme && props.theme.spacing && props.theme.defaultSpace
        ? props.theme.spacing[props.theme.defaultSpace]
        : '0'};
  }

  ${createStyles}
`;

const Box: FC<PropsWithChildren<BoxProps>> = ({
  children,
  order = 0,
  sticky,
  ...props
}) => (
  <BoxSC order={order} {...props}>
    {sticky ? (
      <div className={`sticky top-${sticky}`}>{children}</div>
    ) : (
      children
    )}
  </BoxSC>
);

export default Box;
