import { DocumentType, ModelType } from '@innedit/innedit-type';
import FirebaseFirestore from 'firebase/firestore';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

import {
  FindOptionsProp,
  SearchOptionsProp,
  WatchOptionsProp,
} from '../functions';
import Model, { ModelProps } from './index';

export interface ModelChannelProps<T extends ModelType> extends ModelProps<T> {
  channelId?: string;
}

abstract class ModelChannel<T extends ModelType> extends Model<T> {
  public channelId?: string;

  constructor(props: ModelChannelProps<T>) {
    const { channelId, ...others } = props;
    super(others);

    this.channelId = channelId;
  }

  public clean(values?: Partial<T>, validate?: boolean): Partial<T> {
    return super.clean(
      {
        ...values,
        channelId: this.channelId,
      } as any,
      validate,
    );
  }

  public async create(data: Partial<T>, id?: string): Promise<DocumentType<T>> {
    return super.create({ ...data, channelId: this.channelId }, id);
  }

  public async find(options?: FindOptionsProp<T>): Promise<DocumentType<T>[]> {
    const newOptions = options || {};

    if (!newOptions.wheres) {
      newOptions.wheres = {};
    }

    if (this.channelId) {
      newOptions.wheres.channelId = this.channelId;
    }

    return super.find(newOptions);
  }

  public async search(
    q: string,
    options: SearchOptionsProp = {},
  ): Promise<SearchResponse<DocumentType<T>> | undefined> {
    if (!this.canDoSearch) {
      return undefined;
    }

    const newOptions = options || {};

    if (!newOptions.wheres) {
      newOptions.wheres = {};
    }

    if (this.channelId) {
      newOptions.wheres.channelId = this.channelId;
    }

    return super.search(q, newOptions);
  }

  public watch(
    next: (docs: DocumentType<T>[]) => void,
    options?: WatchOptionsProp,
  ): FirebaseFirestore.Unsubscribe {
    const newOptions = options || {};

    if (!newOptions.wheres) {
      newOptions.wheres = {};
    }

    if (this.channelId) {
      newOptions.wheres.channelId = this.channelId;
    }

    return super.watch(next, newOptions);
  }
}

export default ModelChannel;
