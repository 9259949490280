import { ModelType } from '@innedit/innedit-type';

import Model, { ModelProps } from './index';

export interface ModelEspaceProps<T extends ModelType> extends ModelProps<T> {
  espaceId: string;
}

abstract class ModelEspace<T extends ModelType> extends Model<T> {
  public espaceId: string;

  protected constructor(props: ModelEspaceProps<T>) {
    const { espaceId } = props;
    super(props);

    this.espaceId = espaceId;
  }
}

export default ModelEspace;
