import styled from '@emotion/styled';
import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

interface ButtonGroupProps {
  className?: string;
}

const ButtonGroupEL = styled.div`
  display: flex;
`;

const ButtonGroup: FC<PropsWithChildren<ButtonGroupProps>> = ({
  children,
  className,
}) => {
  const nbChilds = React.Children.count(children);
  const childrenWithProps = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        className: classnames(child.props.className, {
          'rounded-l-none': index > 0,
          'rounded-r-none border-r-0': index < nbChilds - 1,
        }),
      } as any);
    }

    return child;
  });

  return (
    <ButtonGroupEL className={className}>{childrenWithProps}</ButtonGroupEL>
  );
};

export default ButtonGroup;
